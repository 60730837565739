import React from 'react'
import { makeStyles } from './payment_details_design'
import useCustomMediaQuery from '../../hooks/useCustomMediaQuery'

export default function HeadInstruction(props){
    const media = useCustomMediaQuery()
    const styles = makeStyles(media)

    const {
        details, channel_name, channel_map, mode
    } = props

    if(details.status == "Paid"){
        return(
            <div style={styles.textStyle}>
                Your {mode == 'wallet' ? 'add money': 'payment'} via <strong> {channel_name} </strong> 
                is complete! Your reference no. is <strong>#{details.code}</strong>. 
                Please check the {mode == 'wallet' ? 'add money': 'payment'} details below:
            </div>
        )
    }
    else if(details.status == "Pending" && details.code != null){
        if(details.channel_type === 'OTC' && details.channel !== 'i2i' && details.channel !== 'manual_add'){
            if(mode == 'wallet'){
                return(
                    <div style={styles.textStyle}>  
                        This is your instruction to add money to your account via <b>{channel_name}</b> with reference number {details.code}. Read how to pay instructions below.
                    </div>
                )
            }

            else{
                return(
                    <div style={styles.textStyle}>
                        Please make a payment at any 
                        { channel_map ?
                        <span>
                            <a href={channel_map } style={{color: '#F5922F'}} target="_blank"><strong> <u>{channel_name} branches</u> </strong></a>
                        </span>
                        :
                        <strong> {channel_name } branches </strong>
                        }
                        {
                            details.channel == 'payngo' ? 
                            'nationwide and read how to pay instructions below.' :
                            'nationwide and present this barcode or reference number to the cashier.'
                        }
                         
                    </div>
                )
            }
            
        } 
        
        else if (details.channel == 'manual_add'){
            return (
                <div style={styles.textStyle}>
                    This is your receipt for your add money transaction. Please check the details below.
                </div>
            )
        }

        else if (details.channel === 'i2i') {
            return (
                <div style={styles.textStyle}>
                    Please make a payment at any i2i Partner Rural Bank with <b>Pay Bills</b> service.
                </div>
            )
        }
        
        else{
            return(
                <div style={styles.textStyle}>
                    This is your payment instruction via <b>{channel_name}</b> {details.channel === 'ubpcs' && <text>(processed by <b>Unionbank of the Philippines</b>)</text> } with reference number <b>{details.code}</b>. Know how to pay by reading the instructions below your payment details.
                </div>
            )
        }
    }
    else if(details.status == 'Cancelled'){
        if(details.channel === 'ubpcs') {
            return(
                <div style={styles.textStyle}>
                    We were unable to proceed with your debit / credit card payment.

                    <div className="top-20">
                        <div className="wc-header">Reason:</div>
                        <div className="top-12">
                            {details.remarks}
                        </div>
                    </div>
                </div>
            )
        }

        else {
            return(
                <div style={styles.textStyle}>Your order has been cancelled from {details.owner}.</div>
            )
        }
    }
    else{
        return(
            <div style={styles.textStyle}>Your order has been expired.</div>
        )
    }


}