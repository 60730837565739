import React from 'react';
import AppBar from '../components/appBar/AppBar'
import axios from 'axios'
import {Handle401} from '../components/handle401/handle401'
import {history} from '../store/history'
import LogoutTimer from '../components/timer/logout_timer'
// import {restartTimer} from '../components/timer/logout_timer'
// import DashboardTutorial from '../components/tutorials/dashboard_tutorial';
import TermsModal from '../components/terms_and_privacy/terms_modal'
import {showTermsModal} from '../components/terms_and_privacy/terms_modal'
import {connect} from 'react-redux';
import { SET_CHILD_ENDPOINTS } from './Mobile/mobileAppConstants';
import MetaTags from 'react-meta-tags'
import DashboardTutorial from '../components/tutorials/segments_tutorial';

export async function getLatestBalance(){

    const testMode = history.location.pathname.indexOf('/test') > -1 && localStorage.getItem("userType") === "SW";
    let endpoint = testMode ? "/api/sandbox/get_latest_balance/" : "/api/get_latest_balance/";
    let url = process.env.REACT_APP_API_URL + endpoint;
    const yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }
    try{
        if(window.location.pathname == '/dashboard' || window.location.pathname == '/mobile/dashboard')
        {
          url = localStorage.getItem("userType") == "CO" ? url+"?step="+localStorage.getItem("KYBStep") : url+"?step="+localStorage.getItem("Step")
        }

        let response = await axios.get(url,yourConfig)
        localStorage.setItem('balance',response.data.balance)
        localStorage.setItem('tradeName', response.data.qr_trade_name)
        localStorage.setItem('SbTradeName', response.data.sb_qr_trade_name)
        localStorage.setItem('Step', response.data.kyc_step)
        localStorage.setItem('KYBStep', response.data.kyb_step)
        localStorage.setItem('Level', response.data.kyc_level)
        localStorage.setItem('payout_enabled', response.data.payout_enabled)
        localStorage.setItem('shipping_enabled', response.data.shipping_enabled)
        localStorage.setItem('admin_shipping_enabled', response.data.admin_shipping_enabled)
        localStorage.setItem("admin_711_enabled", response.data.admin_711_enabled)
        localStorage.setItem("banks_disabled", JSON.stringify(response.data.banks_disabled))
        localStorage.setItem("channels_disabled", JSON.stringify(response.data.channels_disabled))
        localStorage.setItem("contact", response.data.contact)
        localStorage.setItem("industry", response.data.industry)
        localStorage.setItem("userType", response.data.user_type)
        localStorage.setItem("profilePicture", response.data.profile_picture)
        localStorage.setItem("termsAgreed", response.data.terms_agreed)
        localStorage.setItem('cc_enabled', response.data.cc_enabled)
        localStorage.setItem('cc_balance', response.data.cc_balance)
        localStorage.setItem('is_child', response.data.is_child)
        localStorage.setItem('merchant_bias', response.data.merchant_bias)
        localStorage.setItem('survey_enterprise', response.data.survey_enterprise)
        localStorage.setItem('uid', response.data.bux_merchant_id)
        localStorage.setItem('is_multiwallet', response.data.is_multiwallet)
        localStorage.setItem('is_sb_multiwallet', response.data.is_sb_multiwallet)
        localStorage.setItem('float_balance', response.data.float_balance);
        localStorage.setItem('segments', response.data.bux_segment.toLowerCase())
        localStorage.setItem('banner_url', response.data.banner_url)
        localStorage.setItem('sub_checkout_enabled', response.data.sub_checkout_enabled);
        localStorage.setItem('is_payout_restricted', response.data.is_payout_restricted);
        localStorage.setItem('has_cashbux_privilege', response.data.has_cashbux_privilege);
        localStorage.setItem('instapay_payment_channel', response.data.instapay_payment_channel);
        localStorage.setItem('is_bank_approved', response.data.is_bank_approved);
        localStorage.setItem('sms_notifications_enabled', response.data.sms_notifications_enabled);
        localStorage.setItem('subwallet_type', response.data.subwallet_type);
        localStorage.setItem("subwallet_types_enabled", response.data.subwallet_types_enabled);
        localStorage.setItem('minimum_approver_count', response.data.minimum_approver_count);

        if(response.data){
            
            var defined_wallet_settings = {
                'generate_payment_link_enabled': response.data.generate_payment_link_enabled,
                'checkout_enabled': response.data.checkout_enabled,
                'my_qr_code_enabled': response.data.my_qr_code_enabled == "enabled",
                'add_money_enabled': response.data.add_money_enabled,
                'transfer_money_enabled': response.data.transfer_money_enabled,
                'payout_enabled': response.data.payout_enabled,
                'payout_type': response.data.payout_type,
                'link_store_enabled': response.data.link_store_enabled,
                'exclusive_banners_enabled': response.data.exclusive_banners_enabled,
                'is_maker_approver_enabled': response.data.is_maker_approver_enabled,
                'wallet_transfer_id_enabled': response.data.wallet_transfer_id_enabled
            }

            localStorage.setItem("defined_wallet_settings", JSON.stringify(defined_wallet_settings));
            localStorage.setItem("GCash_date", response.data.gcash_created_at)
            
            if(response.data.bux_segment === 'NIL' && ['CO', 'ME'].includes(response.data.user_type)){
                history.push('/more_about')
            }
            if ('kyb_required' in response.data){
                localStorage.setItem('kybRequired', response.data.kyb_required);
            } 
            if('subuser_role' in response.data){
                localStorage.setItem('subuser_role', response.data.subuser_role);
            }
            if(response.data.survey_enterprise === false && response.data.user_type == "CO" && response.data.is_child == false)  {
                if(!response.data.terms_agreed){
                    showTermsModal();
                } else {
                    // history.push('/survey_enterprise');
                }
            }
            if(!response.data.terms_agreed && ["ME","CO","SW"].includes(response.data.user_type)){
                showTermsModal()
            }
            return response.data
        }
        else{
            return false
        }

    }
    catch(error){
    //   if (error.response.status == 401) {
    //     Handle401();
    //   }       
    }
}


function mapDispatchToProps(dispatch) {
    return {
        dispatchSetMobileEndpoints: (endpoints) => {
            dispatch({
                'type': SET_CHILD_ENDPOINTS,
                'endpoints': endpoints,
            })
        }
    }
}


class Main extends React.Component{
    
    componentDidMount(){
        if(localStorage.getItem("jwtToken") != ""){
            this.getBalance()
        }
    }

    

    async getBalance(){
        let response = await getLatestBalance()
        if(response){
            this.setState({
                hidden: false,
                custId: response.customer_id,
            })

            this.props.dispatchSetMobileEndpoints(response.endpoints);
        }
    }
    constructor(props){
        super(props)
        this.state = {
          hidden: true,
          custId: null,
        }
    }
    render(){
        const {children, noAppBar}= this.props;
        const pathname = window.location.pathname;

        return(
        <div style={{height: '100vh', backgroundColor: '#F4F6F9'}}>
            <MetaTags>
                <title>Bux | All-in-One Payment Gateway for Online Sellers, SMEs</title>
                <meta
                    name="description"
                    content="Bux | All-in-One Payment Gateway for Online Sellers, SMEs"
                />
                <meta property="og:description" 
                    content="Send and receive payments through over-the-counter channels, online banking and e-wallets with Bux, the Philippines’ leading payment gateway."/>
                <meta property="og:image" content="%PUBLIC_URL%/buxcover.jpg" />

            </MetaTags>
            <DashboardTutorial custId={this.state.custId} /> 
            {/* bugfix for KYC remarks unable to type (weird bug) */}
            {
                noAppBar?
                {children}
                :   
                <AppBar children={children} />
            }
            <LogoutTimer/>
            <TermsModal/>
            {/* <div style={{display:'flex'}}>
            <div style={{flex:1}} hidden={this.state.hidden}><SideBar/></div>
            <div style={{
                flex:10, 
                paddingTop:'5%', 
                paddingBottom: pathname === '/dashboard'? '0' : '50px', height: '100%', 
                backgroundColor: '#F4F6F9', 
                paddingLeft: '220px'
            }} hidden={this.state.hidden}> {children}
            </div>
            </div> */}
        </div>)
    }
}

export default connect(null, mapDispatchToProps)(Main);
