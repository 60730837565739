import React from 'react'
import {isMobile} from 'react-device-detect'
import {Card, Button, Modal, Typography, Icon} from 'antd'
import ProgressCard from './progress_card'
import PersonalInfo from './personal_info'
import ContactInfo from './contact_info'
import EmploymentInfo from './employment_info'
import IdentityVerification from './identity_verification'
import BusinessInfo from './business_info'
import * as Style from './kyc_style'
import GreatJobCard from './great_job_card'
import ReviewCard from './review_card'
import ReviewSubmit from './review_submit'
import RejectedCard from './rejected_card'
import LearnCard from './learn_card'
import axios from 'axios'
import {history} from '../../../store/history'
import Submitted from '../../../static/icons/submitted.svg'
import MetaTag from '../../meta_tag/meta_tag'
import kycServices from '../kycServices';
import TestModeBanner from '../../cards/test_mode/testModeBanner';
import TestModeCard from '../../cards/test_mode/testModeCard';
import TestIcon from '../../../static/icons/test-icon.svg';

export function setStep(edit){
    this.setState({
        edit: edit
    })
    window.scroll({
        top:  0,
        left: 0,
        behavior: 'smooth',
    });
}

export function setScroll(scroll){
    if(this.state.edit >= 6 && this.state.details.business_details.name != "")
    window.scroll({
        top:  scroll,
        left: 0,
        behavior: 'smooth',
    });
}

export async function submitDetails(details){

    let res = await kycServices.submitDetails(details);

    if(res.status == "success"){
        if(res.step == 7 || res.step == 8){
            this.setState({
                submittedModal: true,
            })
        }
        this.setState({
            step: res.step,
            edit: res.step
        })
        this.fetchDetails()
        localStorage.setItem("Step", res.step)
        window.scrollTo(0, 0);
        return "success"
    }
    else{
        return res.message
    }

}

const resubmitDetails = {
    step: 9
}

const segments = localStorage.getItem("segments")
const testMode = (history.location.pathname.indexOf('/test') > -1);

class Application_Main extends React.Component {

    componentWillMount(){
        this.fetchDetails()
    }
    constructor(props) {
        super(props)
    
        this.state = {
          edit: 0,
          step: 0,
          doneFetch: false,
          submittedModal: false,
          details: {},
          loadingResubmit: false,
          isDataFilled: false,
        }

        setStep = setStep.bind(this)
        setScroll = setScroll.bind(this)
        submitDetails = submitDetails.bind(this)
    }

    async fetchDetails(){
        const res = await kycServices.fetchDetails();
        localStorage.setItem("Step", res.step)
        this.setState({
            details: res,
            step: res.step,
            edit: res.step,
            doneFetch: true
        })
    }

    fakeKycDetails = (step) => {
        localStorage.setItem("Step", step)
        this.setState({
            step: step,
            edit: step,
            doneFetch: true,
            isDataFilled: step == 6 ? true : false
        })
    }

    render(){
        const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
        const isXhighReso = window.screen.width * window.devicePixelRatio > 1440 && window.screen.height * window.devicePixelRatio > 900;
        const onReview = (this.state.edit == 6 || this.state.edit == 7 || this.state.edit == 8 || this.state.edit == 9 || this.state.edit == 10) && this.state.doneFetch;
        return(
            <div
                align="center"
                style={{
                    display: 'block', 
                    justifyContent:'center',
                    backgroundColor: '#F4F6F9',
                    height: isMobile && (this.state.edit == 1) && '100vh',
                    padding: isMobile && (this.state.edit == 9 || this.state.edit == 7) ? '2px 16px 140px 16px' : isMobile ? '0px 16px 100px 16px' : '100px 0px 20px 0px',
                }}>
                <MetaTag title={this.state.step < 6 ? "Complete Profile" : this.state.step >= 6 ? "Review Application" : ""}/>
                <TestModeBanner width={'85vw'} top={64} right={0} hidden={!testMode} />
                <TestModeCard
                    cardWidth={isXhighReso ? '40vw' : isHighReso ? '71%' : '89%'}
                    imageSource={TestIcon}
                    header={"You are in Test Mode"} 
                    subText={"All KYC data or information logged in the Test Mode will not be saved and will be cleared after refresh."} 
                    hidden={!testMode} />

                
                <div align="right" style={{width: isXhighReso ? '40vw' : isHighReso ? '71%' : '89%', marginBottom: 16}} hidden={!testMode} >
                    <Button style={Style.filledBtn} onClick={() => this.setState({isDataFilled: true, edit: 6})} hidden={this.state.isDataFilled} >Fill Data</Button>
                    <Button style={Style.filledBtn} onClick={() => this.setState({isDataFilled: false, edit: 0})} hidden={!this.state.isDataFilled}>Reset KYC</Button>
                </div>
                
                <div style={{
                    display: isMobile ? 'block' : 'flex', 
                    justifyContent:'center',
                    textAlign:'left'
                }}>
                    <div style={{display: isMobile ? 'block' : 'flex', paddingBottom: isMobile && '90px'}}>
                        <div style={{
                            position: isMobile ? 'relative' : 'fixed', 
                            marginRight: isMobile ? '0px' : '270px',
                            marginTop: isMobile ? '20px': '0px',
                            marginBottom: isMobile && '32px',
                            overflowY: isMobile? 'hidden' : 'auto',
                            height: isMobile && !onReview && '114px'
                        }} hidden={isMobile && this.state.edit > 5}>
                            <div hidden={isMobile && this.state.edit > 5} style={{marginBottom: isMobile? '0px' : '10px'}}>
                                <ProgressCard testMode={testMode} details={this.state.details} step={this.state.edit} dataFilled={this.state.isDataFilled} />
                            </div>
                            {/* <div hidden={isMobile || this.state.edit > 5}>
                                <LearnCard/>
                            </div>
                            <div style={{...Style.reminderStyle}} hidden={onReview}>
                                <Icon type="exclamation-circle" theme="filled" style={{color: '#909196', backgroundColor: isMobile && '#FFF', borderRadius: 20, fontSize: 16, paddingRight: 8, display: 'flex', alignItems: isMobile? 'flex-start' : 'center'}} />
                                <Typography style={{fontSize: 12, color: '#54575F'}}>{isMobile? 'For iOS users use Chrome or Safari browser for best experience.' : 'For Mac users use Chrome or Safari browser for best experience.'}</Typography>
                            </div> */}
                        </div>
                        <div style={{marginLeft: isMobile ? '0px' : isHighReso? '240px' : '300px', marginTop: isMobile ? '20px' : '0px', backgroundColor: '#F4F6F9'}}>
                            <div align="right" style={Style.backToDashboard}> <a className={`text-${segments}`} onClick={()=>history.push('/dashboard')} hidden={isMobile || [6,7,8,10].includes(this.state.step)}>Back to Dashboard</a></div>
                            <GreatJobCard details={this.state.details} step={this.state.edit} testMode={testMode} dataFilled={this.state.isDataFilled} />

                            <ReviewCard details={this.state.details}/>

                            <RejectedCard details={this.state.details}/>

                            <div hidden={ this.state.edit < 6 || !isMobile} style={{height: isMobile && this.state.edit > 6 ? '16px' : '132px'}}></div>
                                
                            <Card style={Style.formCardStyle} hidden={this.state.doneFetch} loading={true}> </Card>
                            {                               
                                (this.state.edit == 0 && this.state.doneFetch)?
                                <PersonalInfo details={this.state.details} testMode={testMode} fakeKycDetails={this.fakeKycDetails} /> :
                                (this.state.edit == 1 && this.state.doneFetch) ?
                                <ContactInfo details={this.state.details} testMode={testMode} fakeKycDetails={this.fakeKycDetails}/> :
                                this.state.edit == 2 && this.state.doneFetch ?
                                <EmploymentInfo details={this.state.details} testMode={testMode} fakeKycDetails={this.fakeKycDetails}/> :
                                (this.state.edit == 3 || this.state.edit == 4) && this.state.doneFetch ?
                                <IdentityVerification details={this.state.details} testMode={testMode} fakeKycDetails={this.fakeKycDetails}/> :
                                this.state.edit == 5 && this.state.doneFetch ?
                                <BusinessInfo details={this.state.details} testMode={testMode} fakeKycDetails={this.fakeKycDetails}/> : 
                                (this.state.edit == 6 || this.state.edit == 7 || this.state.edit == 8 || this.state.edit == 9 || this.state.edit == 10) && this.state.doneFetch ? 
                                <ReviewSubmit details={this.state.details} testMode={testMode} dataFilled={this.state.isDataFilled} /> : ""
                            }
                        </div>
                    </div>
                    
                    <div style={Style.resubmitDiv} hidden={isMobile || (this.state.edit <= 5 || this.state.step != 9)}>
                        <div style={{display:'flex', justifyContent:'center', paddingTop: '30px'}}>
                            <div style={{width: '850px'}}>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <div style={{...Style.resubmitText, marginLeft: '50px'}}>
                                        Before submitting, make sure all the information is correct and accurate.
                                    </div>
                                    <Button loading={this.state.loadingResubmit} style={{...Style.resubmitBtnStyle, margin: '-15px -100px 0px 0px'}} onClick={()=>{this.setState({loadingResubmit: true}); submitDetails(resubmitDetails)}}>Resubmit Application</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={Style.resubmitDivMob} hidden={!isMobile || (this.state.edit <= 5 || this.state.step != 9)}>
                        <div style={Style.resubmitText} align="center">
                            Before submitting, make sure all the information is correct and accurate.
                        </div>
                        <div style={{display: 'flex', justifyContent:'center', marginTop: '10px'}}>
                            <Button loading={this.state.loadingResubmit} style={Style.resubmitBtnStyle} onClick={()=>{this.setState({loadingResubmit: true}); submitDetails(resubmitDetails)}}>Resubmit Application</Button>
                        </div>
                    </div>
                </div>

                <Modal
                    visible={this.state.submittedModal}
                    onCancel={()=>this.setState({submittedModal: false})}
                    footer={null}
                    >
                    <div style={{marginTop: '50px'}} align='center'>
                        <img src={Submitted} style={{width: '100%'}}/>
                        <div style={Style.boldText}>Successfully Submitted</div>
                        <div style={{...Style.normalText, marginTop: '20px'}}>We’ve got your Profile information, and currently reviewing it. Kindly give us a maximum of 48 hours to evaluate your application to upgrade your account.</div>
                        <div style={Style.normalText}>Haven’t received your status? Please contact us at <a href="mailto:support@bux.ph" style={{color: '#1d82b3'}}>support@bux.ph</a></div>
                        <Button style={{...Style.submitBtnStyle, marginTop: '20px', color: '#fff', fontWeight: '600'}} onClick={()=> { this.setState({submittedModal: false}); window.location.reload()}}>Done</Button>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default Application_Main