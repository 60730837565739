import React, { useState, useEffect } from "react";
import { 
  Button, 
  Modal, 
  message,
  Switch, 
} from "antd";
import { TextField, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./css/merchant_id_settings.css";
import MerchantIDSettingsViewModel from "./merchant_id_settings_viewmodel";

const MerchantIDSettingsModal = (props) => {
  const [isTouched, setIsTouched] = useState(false);
  const [openChannelSelection, setOpenChannelSelection] = useState(false);

  const [selectedChannel, setSelectedChannel] = useState("");

  const [formFieldData, setFormFieldData] = useState({
    channelCode: "",
    channelName: "",
    isRequired: false,
    defaultValue: "",
    defaultMetadata: "",
    id: null,
  });

  const {
    createChannelSubmerchantIDSettings,
    updateChannelSubmerchantIDSettings,
    getChannelSelections,
    setDisplayMessage,
    isSuccess,
    messageString,
    displayMessage,
    channelSelections,
    isLoadingChannelSelections,
  } = MerchantIDSettingsViewModel();

  const {
    editData,
    openModal,
    closeModal,
    submitModal,
  } = props;

  async function submitCategory() {
    if (
      formFieldData.channelCode != "" 
    ) {
      let params = {
        is_required: formFieldData.isRequired,
        default_value: formFieldData.defaultValue,
        default_metadata: formFieldData.defaultMetadata,
      };

      if (editData) {
        updateChannelSubmerchantIDSettings(params, formFieldData.id);
      } else {
        createChannelSubmerchantIDSettings({...params, channel_code: formFieldData.channelCode});
      }
    } else {
      setIsTouched(true);
    }
  }

  async function clearForm() {
    setFormFieldData({
      channelCode: "",
      channelName: "",
      isRequired: false,
      defaultValue: "",
      defaultMetadata: "",
      id: null,
    });
    setIsTouched(false);
    await getChannelSelections("");
  }

  function updateFields(fields) {
    setFormFieldData({ ...formFieldData, ...fields });
  }

  useEffect(() => {
    if (editData) {
      setFormFieldData({
        channelCode: editData.channel_code,
        channelName: editData.channel_name,
        defaultValue: editData.default_value,
        defaultMetadata: editData.default_metadata ? JSON.stringify(editData.default_metadata) : "",
        isRequired: editData.is_required,
        id: editData.id,
      });
    }
  }, [editData]);

  useEffect(() => {
    if (formFieldData.channelCode == "" && openChannelSelection) {
      getChannelSelectionOptions();
      return;
    }

    if (channelSelections && openChannelSelection) {
      const exists = channelSelections.some((channel) =>
        channel.code
          .toLowerCase()
          .includes(formFieldData.channelCode.toLowerCase())
      );

      if (!exists) {
        getChannelSelectionOptions();
      }
    }
  }, [formFieldData.channelCode]);

  useEffect(() => {
    if (!displayMessage) {
      return;
    }

    if (isSuccess) {
      message.success(messageString, 3, () => { setDisplayMessage(false) });
      clearForm();

      if (editData) {
        closeModal();
      } else {
        submitModal();
      }
    } else {
      message.error(messageString, 3, () => { setDisplayMessage(false) });
    }
  }, [displayMessage]);

  async function getChannelSelectionOptions() {
    await getChannelSelections(formFieldData.channelCode);
  }

  function displayTextField(label, onChange, value, disabled, className = "") {
    return (
      <Grid item={true} className={className}>
        <TextField
          id="outlined-basic"
          label={label}
          disabled={disabled}
          margin="normal"
          variant="outlined"
          fullWidth
          className={isTouched && value == "" ? `error-field` : ``}
          onChange={onChange}
          value={value}
        />
      </Grid>
    );
  }

  return (
    <Modal
      title={
        editData ? "Edit Merchant ID Settings" : "Add Merchant ID Settings"
      }
      centered
      visible={openModal}
      bodyStyle={{ maxHeight: '400px', overflowY: 'auto' }}
      onCancel={() => {
        clearForm();
        closeModal();
      }}
      maskClosable={false}
      footer={[
        <Button
          key="back"
          onClick={() => {
            clearForm();
            closeModal();
          }}
          className="secondary-btn"
        >
          Cancel
        </Button>,
        <Button
          className="primary-btn"
          key="submit"
          type="primary"
          onClick={() => submitCategory()}
        >
          {editData ? "Update" : "Add"}
        </Button>,
      ]}
      className="merchant-category-modal"
    >
      <Grid container>
        <div className="field-row-container">
          <Grid item={true} className="full-width-grid-item">
            <div className="field-row-container">
              <Autocomplete
                style={{ flexGrow: "1", paddingRight: "8px", marginBottom: "8px" }}
                open={openChannelSelection}
                onOpen={() => {
                  setOpenChannelSelection(true);
                }}
                onClose={() => {
                  setOpenChannelSelection(false);
                }}
                onBlur={() => {
                  updateFields({ channelCode: selectedChannel });
                }}
                getOptionSelected={(option, value) =>
                  option.code === value.code
                }
                getOptionLabel={(option) => option.code}
                options={channelSelections}
                loading={isLoadingChannelSelections}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Channel"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingChannelSelections ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputendAdornment}
                        </React.Fragment>
                      ),
                    }}
                    onChange={(e) => {
                      if (e.target.value == "") {
                        setFormFieldData({
                          ...formFieldData,
                          channelCode: "",
                          channelName: "",
                        });
                      } else {
                        updateFields({ channelCode: e.target.value });
                      }
                    }}
                    value={formFieldData.channelCode}
                  />
                )}
                onChange={async (option, value) => {
                  setFormFieldData({
                    ...formFieldData,
                    channelCode: value && value.code ? value.code : "",
                    channelName: value && value.name ? value.name : "",
                  });
                  setSelectedChannel(value && value.code ? value.code : "");
                }}
                inputValue={formFieldData.channelCode}
                disabled={editData ? true : false}
              />

              <div>
                <b>Required</b>
                <div className="field-row-container">
                  <Switch 
                    checked={formFieldData.isRequired} 
                    onChange={() => updateFields({ isRequired: !formFieldData.isRequired })}
                    style={{margin: '12px 0px', backgroundColor: `${formFieldData.isRequired ? '#F5922F' : '#D1D5DD'}`}} 
                  />
                </div>
              </div>
            </div>

            {isTouched && formFieldData.channelCode == "" && (
              <p className="error-field-message" style={{ marginTop: "2px" }}>
                This is a required field
              </p>
            )}
          </Grid>
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Channel Name",
            (e) => updateFields({ channelName: e.target.value }),
            formFieldData.channelName,
            true,
            "full-width-grid-item",
          )}
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Default Value",
            (e) => updateFields({ defaultValue: e.target.value }),
            formFieldData.defaultValue,
            false,
            "full-width-grid-item",
          )}
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Default Metadata",
            (e) => updateFields({ defaultMetadata: e.target.value }),
            formFieldData.defaultMetadata,
            false,
            "full-width-grid-item",
          )}
        </div>
        
      </Grid>
    </Modal>
  );
};

export default MerchantIDSettingsModal;
