import React, {useEffect} from 'react'
import {Input, Select, Checkbox, DatePicker, Divider,
        Icon, Typography, Button, Popover, Row, Col, message
} from 'antd'
import {OTC, BANK, MOBILE, CARD_PAYMENT, segmentStroke} from '../../../components/constants/constants'
import { randomize } from '../../../components/constants/constants';
import { useDispatch, useSelector } from "../../../__test__/react-redux-hooks";
import { getOrders } from '../../../components/orders/orderAction';
import { history } from '../../../store/history';
import { isMobile } from 'react-device-detect';
import cardServices from '../../../components/card/cardServices';
import Pagination from '@material-ui/lab/Pagination';
import crypto from 'crypto'
import moment from 'moment'
import axios from 'axios'

//Components
import TestModeBanner from '../../../components/cards/test_mode/testModeBanner';
import MetaTag from '../../../components/meta_tag/meta_tag';
import GroupButton from '../../../components/custom_components/groupButton';
import TransactionsTable from './table';
import ExportModal from './export_modal';
import TableSettingsModal from './table_settings_modal'

//Icons and Image
import ArrowDown from '../../../static/icons/arrow-down.svg'
import ArrowRight from '../../../static/icons/arrow_right.svg';
import CalendarIcon from '../../../static/icons/calendar.svg';
import QuestionMark from '../../../static/icons/question_mark.svg';
import NoteCard from '../../../components/kyb/forms/noteCard';

let requestToken = ""

const Transactions = () => {

    const dispatch = useDispatch();
    const CheckboxGroup = Checkbox.Group
    const { Option } = Select;
    const {orders, summary, loading, token, error} = useSelector(state => state.orders);

    //Local storage datas
    const userType = localStorage.getItem('userType')
    const jwtToken = localStorage.getItem('jwtToken')
    const testMode = (history.location.pathname.indexOf('/test') > -1 && userType == "CO")
    const isAdmin =  !['ME', 'CO', 'SW'].includes(userType)
    const isEnterprise = userType == 'CO'
    const segments = localStorage.getItem('segments');

    if(userType == 'ME') {
        delete OTC['MLhuillier'];
    }
    const OTC_LIST = Object.keys(OTC)
    const WEB_BANK_LIST = Object.keys(BANK)
    const EWALLET_LIST = Object.keys(MOBILE)
    const CARD_LIST = Object.keys(CARD_PAYMENT)
    
    //State
    const [selectedTab, setSelectedTab] = React.useState('All Status')
    const [searchVal, setSearchVal] = React.useState('');
    const [selectedSummary, setSelectedSummary] = React.useState('Today')
    const [segment, setSegment] = React.useState('All')
    const [emailReceipt, setEmailReceipt] = React.useState("")
    const [ccEnabled, setCCEnabled] = React.useState("")

    //Payment Method
    const [otcCheckedList, setOTCCheckedList] = React.useState(OTC_LIST)
    const [webCheckedList, setWebCheckedList] = React.useState(WEB_BANK_LIST)
    const [walletCheckedList, setWalletCheckedList] = React.useState(EWALLET_LIST)
    const [cardCheckedList, setCardCheckedList] = React.useState(CARD_LIST)
    const [checkAllOTC, setCheckAllOTC] = React.useState(true)
    const [checkAllBank, setCheckAllBank] = React.useState(true)
    const [checkAllWallet, setCheckAllWallet] = React.useState(true)
    const [checkAllCard, setCheckAllCard] = React.useState(true)
    // const [ccSelected, setCCSelected] = React.useState(true)
    const [bnplSelected, setBNPLSelected] = React.useState(true)
    const [snplSelected, setSNPLSelected] = React.useState(true)
    
    //Start Date & End Date
    const [startMoment, setStartMoment] = React.useState("")
    const [startDate, setStartDate] = React.useState("")
    const [endMoment, setEndMoment] = React.useState("")
    const [endDate, setEndDate] = React.useState("")
    const [endOpen, setEndOpen] = React.useState(false)
    
    //Pagination
    const [currentPage, setCurrentPage] = React.useState(1)
    const [pageSize, setPageSize] = React.useState(10)

    //Table Columns
    const [checkedColumns, setCheckedColumns] = React.useState([])

    //Modals
    const [exportModal, setExportModal] = React.useState(false)
    const [tableSettingsModal, setTableSettingsModal] = React.useState(false)

    const subCheckout = localStorage.getItem('sub_checkout_enabled') === 'true';

    // disabled schedule payment request
    const kybStep = localStorage.getItem("KYBStep");
    const approvedStep = [11, 12]
    const level = localStorage.getItem("Level");
    const disablePaymentLink = (userType === "CO" && localStorage.getItem("KYBStep") < 11) && 
                                ((!testMode || localStorage.getItem("userType") === "CO") && 
                                (!approvedStep.includes(parseInt(kybStep)) && !testMode)) || parseInt(level) <= 0;

    const tabs  = ['All Status', 'Paid', 'Pending', 'Cancelled','Expired', 'Refunded']
    const sb_tabs  = ['All Status', 'Paid', 'Pending', 'Cancelled','Expired']
    
    useEffect(()=>{
        let current_path = window.location.pathname

        if(jwtToken == "" || jwtToken == null){
            history.push('/login?next='+current_path)
        }
        else{
            if(isMobile){
                if(!['AD','OP'].includes(userType)){
                    history.push('/mobile/orders')
                }
                else{
                    dispatch(getOrders(generateToken(), selectedTab, 1, 10, startDate, endDate, testMode, selectedSummary, searchVal, getAllKeys()))
                }
            }
            else{
                if(userType == 'PF'){
                    history.push('/recon/7_eleven')
                }
                else if(userType === 'SW' && !subCheckout){
                    history.push('bux_wallet')
                }
                else{
                    checkCCStatus()
                    dispatch(getOrders(generateToken(), selectedTab, 1, 10, startDate, endDate, testMode, selectedSummary, searchVal, getAllKeys()))
                }
            }
        }
    },[])

    const checkCCStatus = async () => {
        try {
          const res = await cardServices.getCardInfo();
          if(res){
            setCCEnabled(res.cc_enabled)
          }
        } catch (error) {
          console.log(error)
        }
    }
    const generateToken = () => {
        let generatedRequestToken = crypto.randomBytes(16).toString('hex');
        requestToken = generatedRequestToken
        return generatedRequestToken
    }
    const disabledStartDate = startValue => {
        if (!startValue) {
          return false;
        }
        return startValue && startValue > moment().endOf('day');
    };
    const disabledEndDate = endValue => {
        if (!endValue || !startMoment) {
          return false;
        }
        return endValue.valueOf() <= startMoment.valueOf();
    }; 
    const onStartChange = value => {
        if(value != null){
          setStartMoment(value)
          setStartDate(value.format("MM-DD-YYYY"));
          setSelectedSummary("Custom")
        }
        else{
          setStartMoment(null);
          setStartDate("");
        }
    }
    const onEndChange = value => {
        if(value != null && startDate != ""){
          setEndMoment(value);
          setEndDate(value.format("MM-DD-YYYY"));
          setSelectedSummary("Custom")
        }
        else{
          setEndMoment(null);
          setEndDate("");
        }
    }
    const handleStartOpenChange = open => {
        if (!open) {
          setEndOpen(true);
        }
    };
    const handleEndOpenChange = open => {
        setEndOpen(open)
    };

    const exportTable = async() => {
        let url = ''
        {
            testMode ?
            url = '/api/sandbox/orders/export/?type=' :
            url = '/api/orders/export/?type='
        }
        let yourConfig = {
          headers: {
              Authorization: "Token " + localStorage.getItem("jwtToken")
          }
    
        }
        try{
          let response = await axios.get(process.env.REACT_APP_API_URL+url+selectedTab+`&summary=${selectedSummary}&email=${emailReceipt}&start=${startDate}&end=${endDate}&search=${searchVal}&payment_methods=${getAllKeys()}&user_type=${segment}&is_export=true`, yourConfig);
          if(response.data.status=='success'){
            message.success("CSV reports successfully sent to your email address")
            setExportModal(false)
          }else{
              message.error(response.data.message)
          }
        } catch (error) {
          message.error("Please try again in a few moments")
        }
    };
    
    
    const applyFilter = () => {
        if(selectedTab === 'Refunded') {
            dispatch(getOrders(generateToken(), selectedTab,currentPage,pageSize, startDate, endDate, testMode, selectedSummary, searchVal, "", "All","", "All", true))
        }
        else {
            dispatch(getOrders(generateToken(), selectedTab, currentPage, pageSize, startDate, endDate, testMode, selectedSummary, searchVal, getAllKeys(), segment))
        }
    }
    const isFiltered = () => {
        return (isAdmin && segment != "All") ||
            selectedSummary != "Today" ||
            startDate != "" ||
            endDate != "" ||
            searchVal != "" ||
            !checkAllOTC ||
            !checkAllBank ||
            !checkAllWallet ||
            // ((isEnterprise || isAdmin) && !ccSelected) ||
            ((isEnterprise || isAdmin) && !bnplSelected) ||
            ((isEnterprise || isAdmin) && !snplSelected)
    }
    const clearFilter = () => {
        setSegment("All")
        setSelectedSummary("Today")
        setStartMoment("")
        setStartDate("")
        setEndMoment("")
        setEndDate("")
        setSearchVal("")
        setOTCCheckedList(OTC_LIST)
        setWebCheckedList(WEB_BANK_LIST)
        setWalletCheckedList(EWALLET_LIST)
        setCardCheckedList(CARD_LIST)
        setCheckAllOTC(true)
        setCheckAllBank(true)
        setCheckAllWallet(true)
        // setCCSelected(true)
        setBNPLSelected(true)
        setSNPLSelected(true)

        if(selectedTab === 'Refunded') {
            // dispatch(getOrders(generateToken(), e.target.value,currentPage,pageSize, startDate, endDate, testMode, selectedSummary, searchVal, getAllKeys(), segment))

            dispatch(getOrders(generateToken(), selectedTab,currentPage,pageSize, "", "", testMode, "Today", searchVal, "", "All","", "All", true))
        }
        else {
            dispatch(getOrders(generateToken(), selectedTab, 1, 10))
        }
    }
    const activePaymentMethod = () => {
        return otcCheckedList != "" || 
            webCheckedList != "" || 
            walletCheckedList != "" ||
            cardCheckedList != "" ||
            // ((isEnterprise || isAdmin) && ccSelected) ||
            ((isEnterprise || isAdmin) && bnplSelected) ||
            ((isEnterprise || isAdmin) && snplSelected)
    }
    const getAllKeys = () => {
        let all_otc = []
        let all_web = []
        let all_wallet = []
        let all_card = []
        let all_bnpl = []
    
        for(let i=0; i < otcCheckedList.length; i++){
          let name = otcCheckedList[i]
          all_otc.push(OTC[name].code)
        }
        for(let i=0; i < webCheckedList.length; i++){
          let name = webCheckedList[i]
          all_web.push(BANK[name].code)
        }
        for(let i=0; i < walletCheckedList.length; i++){
          let name = walletCheckedList[i]
          all_wallet.push(MOBILE[name].code)
        }

        for(let i=0; i < cardCheckedList.length; i++){
            let name = cardCheckedList[i]
            all_card.push(CARD_PAYMENT[name].code)
        }
    
        let otc_w_web = all_otc.concat(all_web)
        let bnpl_ch = otc_w_web.concat(all_bnpl)
        let all_channels = bnpl_ch.concat(all_wallet, all_card)
        
        if(all_otc.includes('ussc')){
            all_channels.push('bc_ussc')
            all_channels.push('USSC')
          }
        if(all_otc.includes('cvm')){
            all_channels.push('CVM')
        }
        if(all_otc.includes('petnet')){
            all_channels.push('PRHB')
        }
        if(all_otc.includes('BAYD')){
          all_channels.push('bayad_center')
        }
        if(all_otc.includes('RLNT')){
          all_channels.push('bc_rlnt')
        }
        // if(ccSelected){
        //   all_channels.push('visamc')
        // }
        if(bnplSelected){
          all_channels.push('billease')
        }
        if(snplSelected){
          all_channels.push('billease_sn')
        }
    
        if(checkAllOTC && checkAllBank && checkAllWallet && checkAllCard && bnplSelected && snplSelected)
        {
          all_channels.push("All")
        }
        return all_channels
      }
    const onChangeOtc = list => {
        setOTCCheckedList(list)
        setCheckAllOTC(list.length == OTC_LIST.length)
    };
    const onChangeWeb = list => {
        setWebCheckedList(list)
        setCheckAllBank(list.length == WEB_BANK_LIST.length)
    }
    const onChangeWallet = list => {
        setWalletCheckedList(list)
        setCheckAllWallet(list.length == EWALLET_LIST.length)
    }
    const onChangeCard = list => {
        setCardCheckedList(list)
        setCheckAllCard(list.length == CARD_LIST.length)
    }
    const onCheckAllOtcChange = e => {
        setOTCCheckedList(e.target.checked ? OTC_LIST : [])
        setCheckAllOTC(e.target.checked)
    };
    const onCheckAllWebChange = e => {
        setWebCheckedList(e.target.checked ? WEB_BANK_LIST : [])
        setCheckAllBank(e.target.checked)
    }
    const onCheckAllWalletChange = e => {
        setWalletCheckedList(e.target.checked ? EWALLET_LIST : [])
        setCheckAllWallet(e.target.checked)
    }

    const onCheckAllCardChange = e => {
        setCardCheckedList(e.target.checked ? CARD_LIST : [])
        setCheckAllCard(e.target.checked)
    }

    const payment_methods = () => (
        <div style={style.paymentMethodCB}>
            <div style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>Over-the-counter</div>
            <Row>
                <Col span={24}>
                    <Checkbox 
                        indeterminate={otcCheckedList.length && otcCheckedList.length < OTC_LIST.length} 
                        checked={checkAllOTC}
                        onChange={(e)=>onCheckAllOtcChange(e)} 
                    >All OTC
                    </Checkbox>
                </Col> 
                <Col>
                    <CheckboxGroup 
                        class="cb_payment_methods"
                        style={{margin:'0px 0px 0px 24px'}} 
                        options={OTC_LIST}
                        value={otcCheckedList} 
                        onChange={(e)=>onChangeOtc(e)} />
                </Col>
            </Row>
            <div style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>Web Banking</div>
            <Row>
                <Col span={24}>
                    <Checkbox 
                        indeterminate={webCheckedList.length && webCheckedList.length < WEB_BANK_LIST.length} 
                        checked={checkAllBank} 
                        onChange={(e)=>onCheckAllWebChange(e)} 
                    >All Web
                    </Checkbox>
                </Col> 
                <Col>
                    <CheckboxGroup 
                        class="cb_payment_methods"
                        style={{margin:'0px 0px 0px 24px'}} 
                        options={WEB_BANK_LIST} 
                        value={webCheckedList} 
                        onChange={(e)=>onChangeWeb(e)} />
                </Col>
            </Row>
    
             <div style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>E-Wallet</div>
            <Row>
                <Col span={24}>
                    <Checkbox 
                        indeterminate={walletCheckedList.length && walletCheckedList.length < EWALLET_LIST.length} 
                        checked={checkAllWallet}
                        onChange={(e)=>onCheckAllWalletChange(e)} 
                    >All E-Wallet
                    </Checkbox>
                </Col> 
                <Col>
                    <CheckboxGroup 
                        class="cb_payment_methods"
                        style={{margin:'0px 0px 0px 24px'}} 
                        options={EWALLET_LIST} 
                        value={walletCheckedList} 
                        onChange={(e)=>onChangeWallet(e)} />
                </Col>
            </Row>
    
            <div hidden={!isEnterprise && !isAdmin} style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>Card Payments</div>
            <Row hidden={!isEnterprise && !isAdmin}>
                <Col span={24}>
                    <Checkbox 
                        indeterminate={cardCheckedList.length && cardCheckedList.length < CARD_LIST.length} 
                        checked={checkAllCard}
                        onChange={(e)=>onCheckAllCardChange(e)} 
                    >All Card Payments
                    </Checkbox>
                </Col> 
                <Col>
                    <CheckboxGroup 
                        class="cb_payment_methods"
                        style={{margin:'0px 0px 0px 24px'}} 
                        options={CARD_LIST} 
                        value={cardCheckedList} 
                        onChange={(e)=>onChangeCard(e)} />
                </Col>
                {/* <Col span={24} key={randomize}>
                    <Checkbox onChange={e => setCCSelected(e.target.checked)} checked={ccSelected}>Visa / MasterCard</Checkbox>
                </Col> */}
            </Row>
    
            <div hidden={!isEnterprise && !isAdmin}  style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>Buy Now Pay Later</div>
            <Row hidden={!isEnterprise && !isAdmin}>
                <Col span={24} key={randomize}>
                    <Checkbox onChange={e => setBNPLSelected(e.target.checked)} checked={bnplSelected}>BillEase</Checkbox>
                </Col>
            </Row>
    
            <div hidden={!isEnterprise && !isAdmin} style={{margin:'10px 0px 10px 0px', fontWeight: '600'}}>Study Now Pay Later</div>
            <Row hidden={!isEnterprise && !isAdmin}>
                <Col span={24} key={randomize}>
                    <Checkbox onChange={e => setSNPLSelected(e.target.checked)} checked={snplSelected}>BillEase</Checkbox>
                </Col>
            </Row>
        </div> 
    )
    return(
        <div>
            <TestModeBanner width={'100vw'} top={64} right={0} />
            <MetaTag title="Payment Request"/>
            <div style={style.mainDiv}>
                <div style={{...style.tabDiv, margin: isEnterprise ? '0 0 32px 0' : '32px 0px 32px 0px'}}>
                    <GroupButton status={testMode ? sb_tabs : sb_tabs} selected={selectedTab} segments={segments} handleClick={(e) => 
                        {
                            setSelectedTab(e.target.value);
                            if(e.target.value === 'Refunded') {
                                setSelectedSummary("All")
                                dispatch(getOrders(generateToken(), e.target.value,currentPage,pageSize, startDate, endDate, testMode, "All", searchVal, "", "All","", "All", true))
                            }
                            else {
                                dispatch(getOrders(generateToken(), e.target.value,currentPage,pageSize, startDate, endDate, testMode, selectedSummary, searchVal, getAllKeys(), segment))
                            }
                        }} />

                    <div hidden={!isAdmin}>
                        <button className={`outline-btn--${segments}`} style={style.exportBtn} onClick={()=>setExportModal(true)}><Icon type='download'/> Download CSV</button>
                    </div>
                    <a hidden={isAdmin || selectedTab === 'Refunded' || userType === 'SW' || disablePaymentLink} >
                        <div style={{...style.scheduleDiv, borderLeft: `8px solid ${segmentStroke[0]}`}} onClick={()=>history.push(testMode ? '/test/payment_schedules' : '/payment_schedules')}>
                            <div style={{display: 'flex'}}>
                                <img src={CalendarIcon} alt="#" style={{height: '25px', }}/>
                                <div style={{fontSize: '14px', color: '#2b2d33', padding: '2px 0px 0px 10px'}}>
                                    <b>{summary && summary.total_sched != 0 ? `${summary.total_sched} Scheduled payment request` : <div>Create payment request schedule</div>}</b> 
                                </div>
                            </div>
                            <div style={{display: 'flex'}}>
                                <div className={`text-${segments}`} style={{fontSize: '16px', fontWeight: 'bold', padding: '0px 0px 0px 10px'}}>
                                {summary && summary.total_sched != 0 ? 'See more' : ''}
                                </div>
                                <Icon type="arrow-right" className={`text-${segments}`} style={{fontSize: '20px', height: '25px', marginLeft: '10px'}}/>
                            </div>
                        </div>
                    </a>
                </div>
                <div hidden={selectedTab !== "Refunded"} className="top-16">
                    <NoteCard note={
                        <span>Refund option is only applicable to credit and debit card transactions. Contact 
                            <a href="mailto:support@bux.ph?subject=Refund in card payments" className="right-4 left-4 liveColor">
                            <strong>support@bux.ph</strong>
                            </a>
                            for your refund request.
                        </span>
                    }/>
                </div>
                <div style={style.body} className="top-16">
                    <div style={{display: 'flex', float: 'right', padding: '0px 32px 20px 32px'}} hidden={isAdmin}>
                        <div style={{display: 'flex', padding: '10px 0px 0px 0px', marginRight: '10px'}} >
                            <a style={{paddingRight: 4}} onClick={() => setTableSettingsModal(true)}>
                                <Icon type="setting" theme="filled" className={`text-${segments}`}/>
                            </a>
                            <Typography className={`text-${segments}`} style={{color: '#0D3D76', fontSize: 16, fontWeight: 'bold'}}>Table Settings</Typography>
                        </div>
                        <div>
                            <button className={`outline-btn--${segments}`} style={style.exportBtn} onClick={()=>setExportModal(true)}><Icon type='download'/> Download CSV</button>
                        </div>
                    </div>
                    <div hidden={isAdmin}>
                        <Divider/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', padding: '0px 32px 0px 32px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Typography style={style.filter}>Filter</Typography>
                            <a style={style.clearBtnDiv} hidden={!isFiltered()} onClick={()=>clearFilter()}>
                                <Icon style={style.clearIcon} type="close-circle" theme="filled" />
                                <span style={style.clearBtn}>Clear Filter</span>
                            </a>
                        </div>
                        <div style={{display: 'flex', padding: '10px 0px 0px 0px'}} hidden={!isAdmin}>
                            <a style={{paddingRight: 4}} onClick={() => setTableSettingsModal(true)}>
                                <Icon type="setting" theme="filled" className={`text-${segments}`}/>    
                            </a>
                            <Typography className={`text-${segments}`} style={{color: '#0D3D76', fontSize: 16, fontWeight: 'bold'}}>Table Settings</Typography>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent:'flex-start', padding: '16px 32px 24px 32px' }}>
                        {
                            (isAdmin && selectedTab !== 'Refunded') &&
                            <Select 
                                size="large" 
                                placeholder="Account Type" 
                                style={{width: '120px', marginRight: '12px', zIndex: 1}}
                                onChange={(e)=> setSegment(e)}
                                value={segment}
                            >
                                <Option value="All">All</Option>
                                <Option value="ME_">BUxME</Option>
                                <Option value="GIG" >BUxGIG</Option>
                                <Option value="SME" >BUxSME</Option>
                                <Option value="BIZ" >BUxBIZ</Option>
                            </Select>
                        }
                        <Popover content={payment_methods()} trigger="click" placement="bottomLeft">
                            <Button 
                                hidden={selectedTab === 'Refunded'}
                                style={{
                                    ...style.paymentMethod,
                                    justifyContent:'space-between',
                                    color: activePaymentMethod() ? '#0D3D76' : '#000',
                                    border: activePaymentMethod() ? '1px solid #0D3D76' : '1px solid #c5cacf'
                                }}>
                                <div>Payment Method</div> 
                                <img alt="Icon"
                                    src={ArrowDown} 
                                    style={{
                                    marginLeft: '5px', 
                                    color: activePaymentMethod() ? '#1DD28B' : '#000'
                                }}/>
                            </Button>
                        </Popover>
                        <Select 
                            size="large"
                            value={selectedSummary}
                            onChange={(e)=> setSelectedSummary(e)}
                            style={{width: '100px', zIndex: 1, color: '#000'}}
                            >
                            <Option value="All">All Time</Option>
                            <Option value="Today">Today</Option>
                            <Option value="Week" >Last 7 days</Option>
                            <Option value="Month" >Last 30 days</Option>
                            <Option value="Custom" >Custom</Option>
                        </Select>    
                        <DatePicker
                            format='MM/DD/YYYY'
                            disabledDate={disabledStartDate}
                            value={startMoment}
                            size="large"
                            picker="month"
                            onChange={onStartChange}
                            onOpenChange={handleStartOpenChange}
                            placeholder='Start Date'
                            style={style.datePic}
                            suffixIcon={<div style={style.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                            allowClear={false} />
                        <span style={{ paddingTop: 8 }}>-</span>
                        <DatePicker
                            format='MM/DD/YYYY'
                            disabledDate={disabledEndDate}
                            value={endMoment}
                            size="large"
                            picker="month"
                            onChange={onEndChange}
                            onOpenChange={handleEndOpenChange}
                            placeholder='End Date'
                            open={endOpen}
                            style={style.datePic}
                            suffixIcon={<div style={style.suffixIcon}><Icon type="calendar" style={{ fontSize: 22 }} /></div>}
                            allowClear={false} />
                        <Input
                            placeholder="Search"
                            style={style.searchInput}
                            suffix={<Icon type="search" style={{ fontSize: '18px', paddingRight: '8px'}} />}
                            onChange={(e)=>setSearchVal(e.target.value)}
                            value={searchVal}
                        />
                        <button
                            className={`btn--${segments}`} 
                            style={style.applyBtn} 
                            onClick={()=>applyFilter()}
                        >Apply</button>
                    </div>
                    <div style={{display: 'flex', padding: '0px 32px 0px 32px'}}>
                        <Divider/>
                    </div>
                    <div style={{display: 'flex', margin: '-10px 32px 20px 52px'}}>
                        <div style={{display: 'flex', marginRight: '32px'}}>
                            <Typography style={style.text}>No. of Transactions: </Typography>
                            <Typography style={style.text}><b>{summary && summary.total_data}</b></Typography>
                        </div>
                        {/* <div style={{display: 'flex', marginRight: '32px'}}>
                            <Typography style={style.text}>Total Amount: </Typography>
                            <Typography style={style.text}><b>₱ {summary && parseFloat(summary.total).toLocaleString('en-US', {minimumFractionDigits: 2})}</b></Typography>
                        </div>
                        <div style={{display: 'flex'}}>
                            <Typography style={style.text}>Receivable Amount: </Typography>
                            <Typography style={style.text}><b>₱ {summary && parseFloat(summary.receive).toLocaleString('en-US', {minimumFractionDigits: 2})}</b></Typography>
                        </div> */}
                    </div>
                    <TransactionsTable 
                        dataSource={(orders && token == requestToken) ? orders : []} 
                        loading={loading} 
                        checkedColumns={checkedColumns} 
                        selectedTab={selectedTab}
                        fetchData={()=>applyFilter()}
                        segments={segments}
                        userType={userType}
                    />
                    {
                        summary && orders.length > 0 &&
                        <div style={{padding: '20px 32px 20px 32px', display:'flex', justifyContent:'space-between'}}>
                            <div>
                                {(currentPage - 1) * pageSize  + 1} 
                                - 
                                {(currentPage - 1) * pageSize  + summary.page_total} out of {summary.total_data}
                            </div>
                            <div style={{display: 'flex'}}>
                                <Select 
                                    size="small" 
                                    style={{width: '100px'}} 
                                    value={pageSize}
                                    onChange={(e)=>
                                    {
                                        setPageSize(e)
                                        if(selectedTab === 'Refunded') {
                                            dispatch(getOrders(generateToken(), selectedTab,currentPage,e, startDate, endDate, testMode, selectedSummary, searchVal, "", "All","", "All", true))
                                        }
                                        else {
                                            dispatch(getOrders(generateToken(), selectedTab,currentPage,e, startDate, endDate, testMode, selectedSummary, searchVal, getAllKeys(), segment))
                                        }
                                    }}>
                                    <Option value={5}>Show 5</Option>
                                    <Option value={10}>Show 10</Option>
                                    <Option value={15}>Show 15</Option>
                                    <Option value={20}>Show 20</Option>
                                </Select>
                                <Pagination 
                                    count={summary.pages} 
                                    shape="rounded"
                                    page={currentPage} 
                                    size="small"
                                    onChange={(event, page)=>{
                                        setCurrentPage(page)
                                        if(selectedTab === 'Refunded') {
                                            dispatch(getOrders(generateToken(), selectedTab,page,pageSize, startDate, endDate, testMode, selectedSummary, searchVal, "", "All","", "All", true))
                                        }
                                        else {
                                            dispatch(getOrders(generateToken(), selectedTab,page, pageSize, startDate, endDate, testMode, selectedSummary, searchVal, getAllKeys(), segment))

                                        }
                                    }}
                                />
                            </div>
                        </div>
                    }
                    <ExportModal 
                        segments={segments}
                        emailReceipt={emailReceipt} 
                        exportModal={exportModal}
                        setEmailReceipt={(e)=>setEmailReceipt(e.target.value)} 
                        hideModal={()=>setExportModal(false)}
                        fetchData={()=>applyFilter()}
                        exportNow={()=>exportTable()} />

                    <TableSettingsModal 
                        segments={segments}
                        tableSettingsModal={tableSettingsModal}
                        hideModal={()=>setTableSettingsModal(false)}
                        checkedColumns={checkedColumns}
                        setCheckedColumns={setCheckedColumns}
                        testMode={testMode}
                        userType={userType} />
                </div>
            </div>
        </div>
        
    )
}

const style = {
    mainDiv:{
        padding: '32px 24px 32px 24px'
    },
    tabDiv: {
        display: 'flex', 
        justifyContent: 'space-between',
    },
    body:{
        width: '100%',
        padding: '32px 0px 32px 0px',
        backgroundColor: '#FFF'
    },
    header: {
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '20px',
        color: '#2B2D33'
    },
    text: {
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
        color: '#2B2D33',
        marginRight: '10px'
    },
    exportBtn: {
        cursor: 'pointer',
        margin: 0,
        fontSize: '16px',
        fontWeight: '500',
        width: '166px',
        height: '40px'
    },
    filter: {
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#000',

    },
    suffixIcon: {
        marginTop: '-12px',
        marginRight: 12,
        color: '#000'
    },
    datePic: {
        zIndex: 1, 
        height: 40,
        fontSize: 14,
        color: '#2F3542',
        width: 178,
        padding: '0 8px 0 8px'
    },
    applyBtn: {
        zIndex: 1, 
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        width: 72,
        cursor: 'pointer',
    },
    clearBtnDiv: {
        backgroundColor: '#E9EEF4',
        borderRadius: 20,
        padding: '6px 12px',
        margin: '0 12px',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #D4D5D8'
    },
    clearBtn: {
        color: '#2b2d32',
        fontSize: 14,
        paddingLeft: 4,
        fontWeight: '500',
        opacity: '0.8'
    },
    clearIcon: {
        color: '#2b2d32',
        opacity: '0.8',
        fontSize: 12,
    },
    searchInput:{
        zIndex: 1, 
        width: '180px', 
        height: '40px',
        borderRadius: '4px', 
        padding: '0 8px 0 0px' 
    },
    paymentMethod:{
        zIndex: 1, 
        height: '40px', 
        width: '172px',
        paddingTop: '8px', 
        marginRight: '12px',
        display: 'flex',
    },
    paymentMethodCB:{
        width: '220px', 
        backgroundColor: '#fff', 
        padding: '10px', 
        height: '200px',
        overflowY: 'scroll'
    },
    scheduleDiv: {
        width: '428px',
        height: '40px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '8px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
        backgroundColor: '#fff',
        padding: '0px 15px 0px 15px'
    }
}

export default Transactions