import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, Select, Table, Divider, DatePicker, Pagination, Input, message, Card, Icon} from 'antd'
import {history} from '../../../../../store/history.js'
import { getRecon } from '../../../../../components/AdminComponents/recon/reconAction.js';
import moment from 'moment';
import '../../../../../static/css/antd.css'
import axios from "axios";
import UploadModal from '../../../../../components/AdminComponents/recon/upload_modal.js'
import MetaTag from "../../../../../components/meta_tag/meta_tag.js";
import "./css/cashbux-recon.css";


const Option = Select
const ButtonGroup = Button.Group;
const dateFormat = 'MM-DD-YYYY';


const mapStateToProps = (state) => {
  const { recon, cutoffs, loading, error } = state.recon;
  return {
    recon,
    cutoffs,
    loading,
    error,
  };
}

const type_dict = {'Accounted': 'Accounted',
                   'Unaccounted (Partner)': 'Missing'}


const mapDispatchToProps = (dispatch) => {
  return {
    getRecon: bindActionCreators(getRecon, dispatch),
  };
};


class CashbuxCashOutRecon extends React.Component{

  componentDidMount(){
    if(localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null){
      history.push('/login')
    }
    else{
      if(localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO"){
        history.push('/dashboard')
      }
      else if(localStorage.getItem("userType") == "PS"){
        history.push('/orders')
      }
      else if(localStorage.getItem("userType") == "MA"){
        history.push('/overview')
      }else{
        this.props.getRecon('', '', this.state.page_size, 1, 'cashbux_cashout', type_dict[this.state.show]);
      }
    }
  }

  constructor(props){
    super(props)
  }

  state = {
    cutoff : '',
    startDate: '',
    page: 1,
    count: 0,
    page_size: 10,
    fileList: [],
    uploading: false,
    alertMessage: '',
    showAlert: false,
    alert: 'success',
    show: 'Accounted',
    emailReceipt: '',
    modal: false,
    hover: false,
    hover2: false
  }

  handleStartDate(date, dateString) {
    if (dateString != "" && moment(dateString) > moment(this.state.cutoff)) {
      message.error("Invalid start date");
      this.setState({
        startDate: ""
      });
      return;
    }

    this.setState({
      startDate: dateString,
      page: 1
    });

    // if (this.state.cutoff != "" && dateString != "") {
      this.props.getRecon(dateString, this.state.cutoff, this.state.page_size, 1, 'cashbux_cashout', type_dict[this.state.show]);
    // }
  }

  handleChangeDate(date, dateString) {
    if (dateString != "" && moment(dateString) < moment(this.state.startDate)) {
      message.error("Invalid end date");
      this.setState({
        cutoff: ""
      });
      return;
    }

    this.setState({
      cutoff: dateString,
      page: 1
    });

    // if (this.state.startDate != "" && dateString != "") {
      this.props.getRecon(this.state.startDate, dateString, this.state.page_size, 1, 'cashbux_cashout', type_dict[this.state.show]);
    // }
  }

  pageSelect = (e) => {
    this.setState({
      page: e,
    })
    this.props.getRecon(this.state.startDate, this.state.cutoff, this.state.page_size, e, 'cashbux_cashout', type_dict[this.state.show]);
  }

  changePageSize = (e) => {
    this.setState({
      page_size: e,
      page: 1
    })
    this.props.getRecon(this.state.startDate, this.state.cutoff, e, 1, 'cashbux_cashout', type_dict[this.state.show]);
  }


  getCount = () => {
    let recon = this.props.recon
    return recon && recon.count ? recon.count : 0;
  }

  headerStyle = {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
    marginBottom: '20px'
  }
  
  tableHeaderStyle = {
    fontStyle: 'normal',
    fontSize: '16px',
    color: '#333333',
    marginBottom: '20px',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '20px',
  }

  columns = [
    {
        title: 'Trace Number',
        dataIndex: 'trace_no',
        render: text => {
            if(text === "Total" || text === "Individual" || text === "ISA Parent" || text === "ISA Merchant") return <b>{text}</b>
            else return text
        }
    },
    {
      title: 'Terminal Code',
      dataIndex: 'terminal_code',
    },
    {
      title: 'Merchant Name',
      dataIndex: 'merchant_name',
    },
    {
      title: 'Card Number',
      dataIndex: 'card_no',
    },
    {
      title: 'Withdrawal Amount',
      dataIndex: 'transaction_amount'
    },
    {
      title: 'Total Fees',
      dataIndex: 'total_fees',
    },
    {
      title: 'Merchant Income',
      dataIndex: 'merchant_income',
    },
    {
        title: 'UBX Income',
        dataIndex: 'ubx_income',
    },
    {
        title: 'AllBank Income',
        dataIndex: 'allbank_income',
    },
    {
        title: 'Tangent Income',
        dataIndex: 'tangent_income',
    },
    {
        title: 'Settled to Merchant',
        dataIndex: 'settled_to_merchant',
    },
    {
        title: 'Total Report Received from AllBank',
        dataIndex: 'total_report_from_allbank',
    },
  ];

  status = [
    'Accounted', 'Unaccounted (Partner)'
  ]

  exportTable = async() => {   
    const yourConfig = {
        headers: {
           Authorization: "Token " + localStorage.getItem("jwtToken")
        }
     }
    
    let response = await axios.get(
      process.env.REACT_APP_API_URL + '/api/recon/cashbux_cashout/?email='+this.state.emailReceipt+'&start='+this.state.startDate+'&end='+this.state.cutoff+'&export='+true+'&type='+type_dict[this.state.show] ,yourConfig
    );
    if(response.data.status=='success'){
      
      message.success(response.data.message)
    }else{
      message.error(response.data.message)
    }
  };

  advanceChannel = async() => {   
    const yourConfig = {
        headers: {
           Authorization: "Token " + localStorage.getItem("jwtToken")
        }
     };

    let params = {
      start_date: this.state.startDate,
      end_date: this.state.cutoff,
      type: "cashout",
    };
    
    let response = await axios.post(
      process.env.REACT_APP_API_URL + '/api/advancing/cashbux/',  params, yourConfig
    );
    if(response.data.status=='success'){     
      message.success(response.data.message)
    }else{
      message.error(response.data.message)
    }
  };

  disabledStartDates = (current) => {
      // Cannot select days before today and today
      let isDisabled = current && current > moment().endOf('day');

      if (this.state.cutoff) {
        // Cannot select days after the end date, if available
        isDisabled = isDisabled || current > moment(this.state.cutoff, dateFormat).startOf('day');
      }

      return isDisabled;
  }

  disabledEndDates = (current) => {
      // Cannot select days in the future
      let isDisabled = current && current > moment().endOf('day');

      if (this.state.startDate) {
          // Cannot select days before the start date, if available
          isDisabled = isDisabled || current < moment(this.state.startDate, dateFormat).startOf('day');
      }

      return isDisabled;
  }

  closeUploadModal = () => {
    this.setState({
      modal: false
    })
    this.props.getRecon(this.state.startDate, this.state.cutoff, this.state.page_size, this.state.page, 'cashbux_cashout', type_dict[this.state.show]);
  }

  updateTransactions = async () => {
    const yourConfig = {
      headers: {
         Authorization: "Token " + localStorage.getItem("jwtToken")
      }
    }
    
    let response = await axios.get(
      process.env.REACT_APP_API_URL + '/api/recon/cashbux_cashout/update/', yourConfig
    );

    if(response.data.status=='success') {
      message.success(response.data.message)
      this.setState({
        page: 1,
      })
      this.props.getRecon(this.state.startDate, this.state.cutoff, this.state.page_size, 1, 'cashbux_cashout', type_dict[this.state.show]);
    } else {
      message.error(response.data.message)
    }
  }

  render(){

    let recon = this.props.recon ? this.props.recon : {"transactions": []};
      return(
        <div style={{ margin: '24px 2.5em 2.5em', paddingBottom: '50px' }}>
          <MetaTag title="CashBux Recon Cash Out" />
          <div style={this.headerStyle}>Recon Cash Out</div>
          <Button
            type='primary'
            className='update-transaction-btn'
            onClick={this.updateTransactions}
          >
            <Icon type="reload"/>
            Update Transactions
          </Button>
          <div className='recon-header-buttons'>
            <ButtonGroup>
              {
                this.status.map((item) => {
                return <Button
                  key={item.toLocaleLowerCase()}
                  onClick={() => { 
                    this.setState({
                    show: item
                    });
                    this.props.getRecon(this.state.startDate, this.state.cutoff, this.state.page_size, 1, 'cashbux_cashout', type_dict[item]);
                  }}
                  className={"cashbux-recon-tab-btn " + (this.state.show === item ? ' active' : '')}
                    style={{
                      // color: this.state.show === item ? '#ffffff' : '#2b2d33',
                      // backgroundColor: this.state.show === item ? '#40a9ff' : '#fff',
                    }}>{item}</Button>
                })
              }
            </ButtonGroup>
            <div>
              <Input
                placeholder="Email"
                style={{ width: '150px', height: '40px',marginRight: '10px' }}
                onChange={(e)=> {
                  this.setState({emailReceipt: e.target.value});  } }
              /> 
              <Button
                size="large"
                onClick={()=>this.exportTable()}
              >
                <Icon type="download" />
                Export CSV
              </Button>
            </div>
          </div>
          <Card>
            <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
              <div className='recon-filter'>
                <DatePicker
                    placeholder="Start Date"
                    disabledDate={this.disabledStartDates} 
                    format={dateFormat} 
                    onChange={this.handleStartDate.bind(this)} 
                    value={this.state.startDate != "" ? moment(this.state.startDate) : null}
                />
                <span className='date-pic-hyphen'>-</span> 
                <DatePicker
                  placeholder="End Date"
                  disabledDate={this.disabledEndDates} 
                  format={dateFormat} 
                  onChange={this.handleChangeDate.bind(this)} 
                  value={this.state.cutoff != "" ? moment(this.state.cutoff) : null}
                />
                <Divider type="vertical"/>
                <Button
                    style={{
                      height: '32px',
                      opacity: this.state.cutoff == '' ? '0.5' : 1,
                      fontSize: '16px'
                    }}
                    disabled={this.state.cutoff == ''}
                    onClick={()=>{
                      this.setState({modal:true})
                    }}
                    hidden={localStorage.getItem("userType") == "SD"}
                    onMouseEnter={()=>this.setState({hover: true})}
                    onMouseLeave={()=>this.setState({hover: false})}
                    >
                    Upload CSV
                </Button>
                <Button
                    style={{
                      height: '32px',
                      opacity: this.state.cutoff == '' ? '0.5' : 1,
                      fontSize: '16px'
                    }}
                    type='primary'
                    disabled={this.state.cutoff == ''}
                    onClick={()=>{
                      this.advanceChannel();
                    }}
                    hidden={localStorage.getItem("userType") == "SD"}
                    onMouseEnter={()=>this.setState({hover2: true})}
                    onMouseLeave={()=>this.setState({hover2: false})}
                    >
                    Generate Payout
                </Button>
              </div>

              <Table
                className='cashbux-recon-table'
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                columns={this.columns}
                dataSource={recon && recon.transactions ? recon.transactions : []}
                pagination={false}
                scroll={{ x: true }}
              />

              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <div style={{ width: '100%', display: 'flex', textAlign: 'right' }}>
                  <Select
                    showSearch
                    onChange={(ev)=>this.changePageSize(ev)}
                    placeholder="Page Size"
                    style={{ width: '110px', marginBottom: '10px', marginLeft: 'auto', marginRight: '20px' }}
                    size="small"
                  >
                    <Option key={1} value={5}>Show 5</Option>
                    <Option key={2} value={10}>Show 10</Option>
                    <Option key={3} value={20}>Show 20</Option>
                    <Option key={4} value={50}>Show 50</Option>
                  </Select>   
                  <Pagination 
                  size="small"
                  total={this.getCount()} 
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  onChange={this.pageSelect} 
                  defaultCurrent={1} key={Math.random()} 
                  pageSize={this.state.page_size} 
                  current={this.state.page}
                  />
                </div>
              </div>
            </div>
          </Card>
          <UploadModal modal={this.state.modal} channel="cashbux_cashout" startDate={this.state.startDate} cutoff={this.state.cutoff} closeModal={this.closeUploadModal}/>
        </div>
      )
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(CashbuxCashOutRecon);