import React,{useEffect} from 'react';
import { makeStyles } from '../payment_details_design'
import {Divider, Button, Icon} from 'antd';
import {history} from '../../../store/history'
import wallet from "../../../static/img/wallet.svg";
import Reminder from "../../../static/icons/reminder.svg";
import { channels } from '../payment_const'
import EcpayDisclaimer from './ecpay_disclaimer';
import BayadCenterDisclaimer from './bayad_center_disclaimer';
import useCustomMediaQuery from '../../../hooks/useCustomMediaQuery';

const OtcPD = props => {
    const media = useCustomMediaQuery()
    const styles = makeStyles(media)

    const {
        channel_name, details, map
    } = props
    
    let channel = details.channel
    let channelInstructions = channel && channels[channel]  ? channels[channel].instruction : [];
    let channelDisclaimer = channel && channels[channel]  ? channels[channel].disclaimer : '';
    let ecpayDisclaimerList = ['posible', 'ussc', 'shopwise', 'rustan', 'wellcome','cvm','ecpay','ecpay_rdp', 'ecpay_rlnt']
    let bayadCenterDisclaimerList = ['bayad_center', 'bc_ussc', 'bc_rlnt', 'villarica', 'petnet', 'tmbntng']

    const promoMinAmount = details && details.min_promo_amount? details.min_promo_amount : 50;
    const promoMaxDiscount = details && details.max_promo_discount? details.max_promo_discount : 20;
    const promoType = details && details.promo_type? details.promo_type : 'FI';
    const promoDiscount = details && details.promo_discount? details.promo_discount : 0;

    const validateAmount = (amount) => {
        if ( amount < 50 ) {
            return 50;
        } 
        return amount;
    }

    const computeDiscount = (generatedAmount) => {
        // const generatedAmount = generatedAmount? generatedAmount : 0;
        
        const discountedAmount = promoType === 'FI' ? (generatedAmount - promoDiscount) : ( generatedAmount - (promoDiscount * generatedAmount)/ 100);

        if ( discountedAmount < promoMinAmount ) {
            
            if((discountedAmount - promoMinAmount + promoDiscount) > promoMaxDiscount) {
                return promoMaxDiscount;
            }
          return (discountedAmount - promoMinAmount + promoDiscount);
        } 


        const totalDeductionAmount = promoType === 'FI'? promoDiscount : ((promoDiscount * generatedAmount)/ 100);

        return totalDeductionAmount > promoMaxDiscount ? promoMaxDiscount : totalDeductionAmount;
    }

    const returnTotalAmount = () => {
        if (details && details.amount && details.promo_code) {
            if (details && details.promo_type === 'PE') {
                return parseFloat(validateAmount(details.amount - computeDiscount(details.amount))).toLocaleString('en-US', { minimumFractionDigits: 2 });
            }
            return parseFloat(validateAmount(details.amount - computeDiscount(details.amount))).toLocaleString('en-US', { minimumFractionDigits: 2 });
        }
        return parseFloat(details.amount).toLocaleString('en-US', { minimumFractionDigits: 2 })
    }

    const getParam1Param2 = () => {
        const payload = details.payload;
        
        let param1Name = "Param 1";
        let param1Value = "";
        let param2Name = "Param 2";
        let param2Value = "";

        if (payload && (payload.param1 || payload.param2)) {
            if (typeof payload.param1 === "object" || typeof payload.param2 === "object") {
                param1Name = (payload.param1 && payload.param1.name && payload.param1.name != "") ? payload.param1.name : "Param 1";
                param1Value = (payload.param1 && payload.param1.value) ? payload.param1.value : "";
                param2Name = (payload.param2 && payload.param2.name && payload.param2.name != "") ? payload.param2.name : "Param 2";
                param2Value = (payload.param2 && payload.param2.value) ? payload.param2.value : "";
            } else if (typeof payload.param1 === "string" || typeof payload.param2 === "string") {
                param1Value = payload.param1;
                param2Value = payload.param2;
            }
        }

        return (
            <div hidden={!payload.param1 && !payload.param2}>
                <div style={styles.rowStyle} hidden={!payload.param1}>
                    <div style={styles.detailStyle}>{param1Name}</div> 
                    <div style={styles.labelStyle}>{param1Value}</div>
                </div>

                <div style={styles.rowStyle} hidden={!payload.param2}>
                    <div style={styles.detailStyle}>{param2Name}</div> 
                    <div style={styles.labelStyle}>{param2Value}</div>
                </div>

                <Divider/>
            </div>
        )
    }
    
    return (
        <div>

            <div hidden={details.channel == 'manual_add'}>
                <div style={{display: 'flex', justifyContent: 'space-between', padding: media.mobile ? '30px 10px 20px 10px' : '30px 50px 20px 50px'}} hidden={details.code == null}>
                    <div style={styles.detailStyle}>Reference number</div> 
                    <div style={styles.labelStyle}>{details.code}</div>
                </div>
                
                <div style={styles.rowStyle}>
                    <div style={styles.detailStyle}>Merchant name</div> 
                    <div style={{...styles.labelStyle, width: '150px'}} align="right">{details.owner}</div>
                </div>
                

                <div hidden={details.status != 'Pending'}>
                    <div style={styles.rowStyle}>
                        <div style={styles.detailStyle}>Issuance date</div> 
                        <div style={styles.labelStyle}>{details.created}</div>
                    </div>
                    <div style={styles.rowStyle}>
                        <div style={styles.detailStyle}>Expiry</div> 
                        <div style={styles.labelStyle}>{details.expiry}</div>
                    </div>
                </div>

                <div style={styles.rowStyle} hidden={details.status != 'Paid'}>
                    <div style={styles.detailStyle}>Date purchased</div> 
                    <div style={styles.labelStyle}>{details.date_paid}</div>
                </div>

                <div style={styles.rowStyle} hidden={details.status != 'Cancelled'}>
                    <div style={styles.detailStyle}>Date cancelled</div> 
                    <div style={styles.labelStyle}>{details.date_cancelled}</div>
                </div>
            </div>

            <div hidden={details.channel != 'manual_add'}>
                <div style={{display: 'flex', justifyContent: 'space-between', padding: media.mobile ? '30px 10px 20px 10px' : '30px 50px 20px 50px'}}>
                    <div style={styles.detailStyle}>Bank name</div> 
                    <div style={styles.labelStyle}>Unionbank of the Philippines</div>
                </div>

                <div style={styles.rowStyle}>
                    <div style={styles.detailStyle}>Bank branch</div> 
                    <div style={styles.labelStyle}>UBP Plaza</div>
                </div>

                <div style={styles.rowStyle}>
                    <div style={styles.detailStyle}>Reference number</div> 
                    <div style={styles.labelStyle}>{details.code}</div>
                </div>
                <div style={styles.rowStyle}>
                    <div style={styles.detailStyle}>Account name</div> 
                    <div style={styles.labelStyle}>UBX Philippines Corporation</div>
                </div>
                <div style={styles.rowStyle}>
                    <div style={styles.detailStyle}>Account number</div> 
                    <div style={styles.labelStyle}>000-590-086-352</div>
                </div>
                <div style={styles.rowStyle}>
                    <div style={styles.detailStyle}>Account type</div> 
                    <div style={styles.labelStyle}>Corporate Regular Checking Account</div>
                </div>
            </div>
            




            <Divider/>

            {getParam1Param2()}
            
            <div hidden={details.status == 'Cancelled'}>
                <div style={{padding: media.mobile ? '0px 10px 20px 10px' : '0px 50px 10px 50px'}}>
                    <div style={{...styles.detailStyle,fontWeight: 'bold'}}>Description</div><br/> 
                    <div style={styles.textStyle}>{details.description}</div>
                </div>

                <Divider/>
            </div>

            <div hidden={details.status != 'Cancelled'}>
                <div style={{padding: media.mobile ? '0px 10px 20px 10px' : '0px 50px 20px 50px'}}>
                    <div style={styles.detailStyle}>Reason</div><br/> 
                    <div style={styles.textStyle}>{details.remark}</div>
                </div>

                <Divider/>
            </div>

            <div style={{display: media.mobile ? 'flex' : '', float: 'right', padding: media.mobile ? '20px 10px 20px 0px' : '0px 50px 20px 0px'}}>
                <span style={{paddingRight: 12, marginTop: media.mobile ? '12px' : ''}}><span style={styles.detailStyle}>{details.status == "Paid" ? "Amount paid:" : "Amount to pay:" }</span> </span>
                <span style={styles.headerStyle}>PHP {returnTotalAmount()}</span>
            </div>

            <div hidden={details.status != 'Pending' || details.code == null}>
                <div hidden={details.status == "Paid"}>
                    <Divider/>

                    <div style={styles.pleaseRememberDiv}>
                        <div style={{paddingRight: 16}}><img src={Reminder} /></div>
                        <div style={{fontSize: '14px', color: '#2b2d33'}}>
                            Please remember that BUx is not responsible for nor does it ensure the delivery, 
                            performance or fulfillment of the goods or service that you are paying for.  
                            BUx only ensures that your payment is processed seamlessly and safely. 
                        </div>
                    </div>
                </div>
                <div hidden={details.channel == 'manual_add'}>

                    <div style={{width: '100%', backgroundColor: '#e6eaf0', margin: '20px 0px 20px 0px', padding: media.mobile ? '8px 0px 4px 10px': '8px 0px 8px 30px'}}>
                        <div style={styles.labelStyle}>How To Pay Instructions</div>
                    </div>
                {
                    channelInstructions.map((item, key) => {
                        console.log('TEST')
                        console.log(channelInstructions);
                        return(
                            <div key={key}>
                                {
                                    channel == 'ecpay_711' ?
                                    <div style={styles.instructionStyle}>
                                        <div style={{marginRight: '30px'}} 
                                                hidden={key === 4 && returnTotalAmount() > 10000}>
                                            <div style={styles.detailStyle}>{
                                                (key === 5 || key === 6) && returnTotalAmount() > 10000 ? key : key+1}.</div> 
                                        </div>
                                        <div style={styles.textStyle} hidden={key === 4 && returnTotalAmount() > 10000}>
                                            {
                                                key == 0 ? 
                                                    returnTotalAmount() <= 10000 ?
                                                        item : <span>Open or download <b>Cliqq app</b> on playstore or appstore.</span> 
                                                    : 
                                                key == 1 ?
                                                    returnTotalAmount() <= 10000 ?
                                                        <div>Click <b>Bills Payment</b> and choose <b>More Billers</b> <b>eCommerce</b></div> : item
                                                    :
                                                key == 2 ?
                                                    returnTotalAmount() <= 10000 ?
                                                    <span>{item} <b>Bux 1 to 10000</b></span> : 
                                                    returnTotalAmount() <= 20000 ?
                                                        <span>{item} <b>Bux 10001 to 20000</b></span> : 
                                                        <span>{item} <b>Bux 20001 to 25000</b></span>: 
                                                key == 3 ?
                                                    <div>
                                                        Input the reference number <b>{details.code}</b>, <b>Account name</b> (customer name) and <b>Amount</b> (exact amount) then click <b>Next</b>.
                                                    </div>
                                                    :
                                                item
                                                
                                            }
                                        </div>
                                    </div>

                                    :

                                    channel == 'posible' ?

                                    <div style={styles.instructionStyle}>
                                        <div style={{marginRight: '30px'}}>
                                            <div style={styles.detailStyle}>{key+1}.</div> 
                                        </div>
                                        <div style={styles.textStyle}>
                                            { 
                                                key == 1 ?
                                                        <div>
                                                            Present the barcode or reference number <b>{details.code}</b>. {item}
                                                        </div>
                                                    
                                                        :
                                                item
                                            }
                                        </div>
                                    </div>

                                    :

                                    channel == '711_direct' ?

                                    <div style={styles.instructionStyle}>
                                        <div style={{marginRight: '30px'}}>
                                            <div style={styles.detailStyle}>{key+1}.</div> 
                                        </div>
                                        <div style={styles.textStyle}>
                                            { 
                                                key == 2 ?
                                                       <div>
                                                           Pay the specified amount of <b>PHP {returnTotalAmount()}</b>.
                                                       </div>
                                                    
                                                       :
                                                item
                                            }
                                        </div>
                                    </div>


                                    :

                                    channel == 'payngo' ?


                                    <div style={styles.instructionStyle}>
                                        <div style={{marginRight: '30px'}}>
                                            <div style={styles.detailStyle}>{key+1}.</div> 
                                        </div>
                                        <div style={styles.textStyle}>
                                            { 
                                                key == 1 ?
                                                        <div>
                                                            Input the reference number <b>{details.code}</b>. {item}
                                                        </div>
                                                    
                                                        :
                                                item
                                            }
                                        </div>
                                    </div>

                                    :

                                    <div style={styles.instructionStyle}>
                                        <div style={{marginRight: '30px'}}>
                                            <div style={styles.detailStyle}>{key+1}.</div> 
                                        </div>
                                        <div style={styles.textStyle}>
                                            { 
                                                key == 4 ?
                                                    channel == 'MLH' ?
                                                        <span>Prepare valid ID and additional <b>surcharge fee of Php 20</b> on top of the amount.</span> : item
                                                    : 
                                                item
                                            }
                                        </div>
                                    </div>

                                }
                                
                            </div>
                        )
                    })
                }
                </div>

                <div style={{...styles.justifyCenter, marginTop: '20px'}} hidden={map == '' || !map}>
                    <div>
                        <Button
                            onClick={()=>window.location.href=map}
                            style={styles.locatorBtn}>
                            <Icon 
                                type="environment" 
                                style={{color: '#FFF', fontSize: '18px', float: 'left'}} 
                                theme="filled"/>
                            
                            View Store Locator
                        </Button>
                    </div>
                </div>

                <div 
                    hidden={map == '' || !map}
                    style={{fontSize: '14px', 
                        color: '#2b2d33', 
                        fontWeight: 'bold', 
                        marginTop:'12px', 
                        textAlign: 'center', 
                        marginBottom: '20px'
                }}>
                    {channel == 'PLWN' ? 'Palawan Express is not included on the list of branches' : 'Limited branches only'}
                </div>
                <div style={styles.dpDisclaimer}>
                    <div style={{color: '#000000', fontWeight: 'bold'}}>Disclaimer:</div> 
                    {
                     ecpayDisclaimerList.includes(channel) ?
                     <EcpayDisclaimer channel={channel} channel_name={channel_name}/>
                     :
                     bayadCenterDisclaimerList.includes(channel) ?
                     <BayadCenterDisclaimer channel={channel} channel_name={channel_name}/>
                     :
                     channelDisclaimer
                    }
                </div>
            </div>            
        </div>
    )
}

export default OtcPD;