import React, { useState, useEffect } from "react";
import { Button, Modal, message } from "antd";
import { TextField, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./css/merchant_ids_v2.css";
import MerchantIDsV2ViewModel from "./merchant_ids_v2_viewmodel";

const MerchantIDsV2Modal = (props) => {
  const [isTouched, setIsTouched] = useState(false);
  const [openUserSelection, setOpenUserSelection] = useState(false);

  const [selectedEmail, setSelectedEmail] = useState("");

  const [formFieldData, setFormFieldData] = useState({
    merchantName: "",
    merchantID: "",
    emailAddress: "",
    channelSubmerchantIDs: [],
  });

  const {
    createOrUpdateChannelSubmerchantIDs,
    getUserSelections,
    setDisplayMessage,
    isSuccess,
    messageString,
    displayMessage,
    userSelections,
    isLoadingUserSelections,
  } = MerchantIDsV2ViewModel();

  const {
    editData,
    openModal,
    closeModal,
    submitModal,
    allChannelSubmerchantIdSettings,
  } = props;

  async function submitCategory() {
    if (
      formFieldData.emailAddress != "" 
    ) {
      let params = {
        user_id: formFieldData.merchantID,
        channel_submerchant_ids: [],
      };

      formFieldData.channelSubmerchantIDs.forEach((channelSubmerchantID, channelSubmerchantIDIdx) => {
        if (
          channelSubmerchantID.value == null
          || (
            editData
            && !editData.channel_submerchant_ids[channelSubmerchantIDIdx]
            && !channelSubmerchantID.value
          )
        ) {
          return;
        }

        params.channel_submerchant_ids.push({
          channel_submerchant_id_setting_id: channelSubmerchantID.channelSubmerchantIDSettingID 
            || allChannelSubmerchantIdSettings[channelSubmerchantIDIdx].id
          ,
          value: channelSubmerchantID.value,
          metadata: channelSubmerchantID.metadata,
        });
      });

      if (editData) {
        createOrUpdateChannelSubmerchantIDs(params, true);
      } else {
        createOrUpdateChannelSubmerchantIDs(params);
      }
    } else {
      setIsTouched(true);
    }
  }

  async function clearForm() {
    let clearedChannelSubmerchantIDs = [];
    allChannelSubmerchantIdSettings.forEach(() => {
      clearedChannelSubmerchantIDs.push({
        channelSubmerchantIDSettingID: null,
        value: null,
        metadata: null,
      });
    });

    setFormFieldData({
      merchantName: "",
      merchantID: "",
      emailAddress: "",
      channelSubmerchantIDs: clearedChannelSubmerchantIDs,
    });
    setIsTouched(false);
    await getUserSelections("");
  }

  function updateFields(fields) {
    setFormFieldData({ ...formFieldData, ...fields });
  }

  useEffect(() => {
    if (editData) {
      setFormFieldData({
        merchantName: editData.user.full_name,
        merchantID: editData.user.id,
        emailAddress: editData.user.email,
        channelSubmerchantIDs: editData.channel_submerchant_ids.map(
          (channelSubmerchantID) => {
            if (!channelSubmerchantID) {
              return {
                channelSubmerchantIDSettingID: null,
                value: null,
                metadata: null,
              }
            }
            return {
              channelSubmerchantIDSettingID: channelSubmerchantID.channel_submerchant_id_setting,
              value: channelSubmerchantID.value,
              metadata: channelSubmerchantID.metadata ? JSON.stringify(channelSubmerchantID.metadata) : "",
            };
          }
        ),
      });
    }
  }, [editData]);

  useEffect(() => {
    if (formFieldData.emailAddress == "" && openUserSelection) {
      getUserSelectionOptions();
      return;
    }

    if (userSelections && openUserSelection) {
      const exists = userSelections.some((merchant) =>
        merchant.merchant_email
          .toLowerCase()
          .includes(formFieldData.emailAddress.toLowerCase())
      );

      if (!exists) {
        getUserSelectionOptions();
      }
    }
  }, [formFieldData.emailAddress]);

  useEffect(() => {
    let channelSubmerchantIDs = [];
    allChannelSubmerchantIdSettings.forEach(() => {
      channelSubmerchantIDs.push({
        channelSubmerchantIDSettingID: null,
        value: null,
        metadata: null,
      });
    });

    updateFields({ channelSubmerchantIDs: channelSubmerchantIDs });
  }, [allChannelSubmerchantIdSettings]);

  useEffect(() => {
    if (!displayMessage) {
      return;
    }

    if (isSuccess) {
      message.success(messageString, 3, () => { setDisplayMessage(false) });
      clearForm();

      if (editData) {
        closeModal();
      } else {
        submitModal();
      }
    } else {
      message.error(messageString, 3, () => { setDisplayMessage(false) });
    }
  }, [displayMessage]);

  async function getUserSelectionOptions() {
    await getUserSelections(formFieldData.emailAddress);
  }

  function displayTextField(label, onChange, value, disabled, className = "") {
    return (
      <Grid item={true} className={className}>
        <TextField
          id="outlined-basic"
          label={label}
          disabled={disabled}
          margin="normal"
          variant="outlined"
          fullWidth
          className={isTouched && value == "" ? `error-field` : ``}
          onChange={onChange}
          value={value}
        />
      </Grid>
    );
  }

  return (
    <Modal
      title={
        editData ? "Edit Merchant IDs" : "Add Merchant IDs"
      }
      centered
      visible={openModal}
      bodyStyle={{ maxHeight: '400px', overflowY: 'auto' }}
      onCancel={() => {
        clearForm();
        closeModal();
      }}
      maskClosable={false}
      footer={[
        <Button
          key="back"
          onClick={() => {
            clearForm();
            closeModal();
          }}
          className="secondary-btn"
        >
          Cancel
        </Button>,
        <Button
          className="primary-btn"
          key="submit"
          type="primary"
          onClick={() => submitCategory()}
        >
          {editData ? "Update" : "Add"}
        </Button>,
      ]}
      className="merchant-category-modal"
    >
      <Grid container>
        <div className="field-row-container">
          <Grid item={true} className="full-width-grid-item">
            <Autocomplete
              style={{ width: "100%", marginBottom: "8px" }}
              open={openUserSelection}
              onOpen={() => {
                setOpenUserSelection(true);
              }}
              onClose={() => {
                setOpenUserSelection(false);
              }}
              onBlur={() => {
                updateFields({ emailAddress: selectedEmail });
              }}
              getOptionSelected={(option, value) =>
                option.merchant_email === value.merchant_email
              }
              getOptionLabel={(option) => option.merchant_email}
              options={userSelections}
              loading={isLoadingUserSelections}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="User Email Address"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingUserSelections ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputendAdornment}
                      </React.Fragment>
                    ),
                  }}
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setFormFieldData({
                        ...formFieldData,
                        merchantName: "",
                        merchantID: "",
                        emailAddress: "",
                      });
                    } else {
                      updateFields({ emailAddress: e.target.value });
                    }
                  }}
                  value={formFieldData.emailAddress}
                />
              )}
              onChange={async (option, value) => {
                setFormFieldData({
                  ...formFieldData,
                  merchantName: value && value.merchant_name ? value.merchant_name : "",
                  merchantID: value && value.id ? value.id : "",
                  emailAddress: value && value.merchant_email ? value.merchant_email : "",
                });
                setSelectedEmail(value && value.merchant_email ? value.merchant_email : "");
              }}
              inputValue={formFieldData.emailAddress}
              disabled={editData ? true : false}
            />

            {isTouched && formFieldData.emailAddress == "" && (
              <p className="error-field-message" style={{ marginTop: "2px" }}>
                This is a required field
              </p>
            )}
          </Grid>
        </div>

        <div className="field-row-container">
          {displayTextField(
            "User ID",
            (e) => updateFields({ merchantID: e.target.value }),
            formFieldData.merchantID,
            true
          )}
          {displayTextField(
            "User Name",
            (e) => updateFields({ merchantName: e.target.value }),
            formFieldData.merchantName,
            true
          )}
        </div>

        {
          allChannelSubmerchantIdSettings.map((value, index) => (
            <>
              <b style={{ paddingTop: "16px" }}>{`${value.channel_name} MID`}</b>
              <div className="field-row-container">
                <div className="field-row-container">
                  {displayTextField(
                    "Value",
                    (e) => {
                      let channelSubmerchantIDsCopy = [...formFieldData.channelSubmerchantIDs];
                      channelSubmerchantIDsCopy[index].value = e.target.value;
                      updateFields({ channelSubmerchantIDs: channelSubmerchantIDsCopy });
                    },
                    formFieldData.channelSubmerchantIDs[index] && formFieldData.channelSubmerchantIDs[index].value || "",
                    false,
                    "full-width-grid-item"
                  )}
                </div>
                {( 
                  value.default_metadata &&
                  <div className="field-row-container">
                    {displayTextField(
                      "Metadata",
                      (e) => {
                        let channelSubmerchantIDsCopy = [...formFieldData.channelSubmerchantIDs];
                        channelSubmerchantIDsCopy[index].metadata = e.target.value;
                        updateFields({ channelSubmerchantIDs: channelSubmerchantIDsCopy });
                      },
                      formFieldData.channelSubmerchantIDs[index] && formFieldData.channelSubmerchantIDs[index].metadata || "",
                      false,
                      "full-width-grid-item"
                    )}
                  </div>
                )}
              </div>
            </>
          ))
        }
      </Grid>
    </Modal>
  );
};

export default MerchantIDsV2Modal;
