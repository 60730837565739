import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Card,
  Typography,
  Button,
  Icon,
  message,
} from "antd";
import { history } from "../../../store/history";
import MerchantIDSettingsModal from "./merchant_id_settings_modal";
import "./css/merchant_id_settings.css";
import MerchantIDSettingsViewModel from "./merchant_id_settings_viewmodel";

const MerchantIDSettings = (_) => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const {
    getChannelSubmerchantIDSettings,
    setDisplayMessage,
    isSuccess,
    messageString,
    displayMessage,
    channelSubmerchantIDSettings,
    isLoading,
  } = MerchantIDSettingsViewModel();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      fixed: "left",
      render: (_, record) => <span>{record.id}</span>,
    },
    {
      title: "Channel",
      fixed: "left",
      render: (_, record) => (
        <div className="channel-name-and-code">
          <p>{record.channel_name}</p>
          <p>{record.channel_code}</p>
        </div>
      ),
    },
    {
      title: `Required`,
      render: (_, record) => {
        if (
          record.is_required != null
          || record.is_required != undefined
        ) {
          return (
            <span>
              {record.is_required ? "True" : "False"}
            </span>
          );
        }
      },
    },
    {
      title: `Default Value`,
      render: (_, record) => {
        if (record.default_value) {
          return (
            <span>
              {record.default_value}
            </span>
          );
        }
      },
    },
    {
      title: `Default Metadata`,
      render: (_, record) => {
        if (record.default_metadata) {
          return (
            <div style={{ 
              maxWidth: '250px',
              overflowWrap: 'break-word', 
            }}>
              {JSON.stringify(record.default_metadata)}
            </div>
          );
        }
      },
    },
    {
      title: "Action",
      fixed: "right",
      render: (record) => {
        return (
          <a style={{ color: "#0A315E" }} onClick={() => openEditModal(record)}>
            Edit
          </a>
        );
      },
    },
  ];

  function closeModal() {
    setOpenModal(false);
    getChannelSubmerchantIDSettings(currentPage, pageSize);
  }

  function onSubmitModal() {
    setOpenModal(false);
    setCurrentPage(1);
    getChannelSubmerchantIDSettings(1, pageSize);
  }

  function openEditModal(record) {
    setEditData(record);
    setOpenModal(true);
  }

  useEffect(() => {
    if (
      localStorage.getItem("jwtToken") == "" ||
      localStorage.getItem("jwtToken") == null
    ) {
      history.push("/login");
    } else if (
      localStorage.getItem("userType") == "ME" ||
      localStorage.getItem("userType") == "CO"
    ) {
      history.push("/dashboard");
    } else if (localStorage.getItem("userType") == "PS") {
      history.push("/orders");
    } else if (localStorage.getItem("userType") == "MA") {
      history.push("/overview");
    } else {
      getChannelSubmerchantIDSettings(currentPage, pageSize);
    }
  }, []);

  useEffect(() => {
    getChannelSubmerchantIDSettings(currentPage, pageSize);
  }, [currentPage]);

  useEffect(() => {
    if (!displayMessage) {
      return;
    }

    if (isSuccess) {
      message.success(messageString, 3, () => { setDisplayMessage(false) });
    } else {
      message.error(messageString, 3, () => { setDisplayMessage(false) });
    }
  }, [displayMessage]);

  return (
    <div
      style={{
        margin: "2em 2.5em 2.5em 2.5em",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
        paddingBottom: "50px",
      }}
    >
      <div>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "20px",
            color: "#2B2D33",
          }}
        >
          Merchant ID Settings
        </Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '1em',
        }}>
          <div style={{ display: "flex", gap: "1em" }}>
            <Button
              size="large"
              className="secondary-btn"
              onClick={() => {
                history.push("/merchant_ids_v2");
              }}
            >
              View Merchant IDs
            </Button>
          </div>
          <div style={{ display: "flex", gap: "1em" }}>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                setEditData(null);
                setOpenModal(true);
              }}
              className="primary-btn"
            >
              <Icon type="plus" />
              Add Merchant ID Settings
            </Button>
          </div>
        </div>
        <Card className="merchant-id-settings-card">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Table
              rowClassName={(_, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              dataSource={(channelSubmerchantIDSettings && channelSubmerchantIDSettings.data)}
              rowKey={record => record.id}
              columns={columns}
              pagination={false}
              loading={isLoading}
              scroll={{ x: true }}
            />

            <Pagination
              size="small"
              total={(channelSubmerchantIDSettings && channelSubmerchantIDSettings.count)}
              defaultPageSize={pageSize}
              defaultCurrent={1}
              current={currentPage}
              onChange={setCurrentPage}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              style={{ paddingTop: "12px", textAlign: "right" }}
            />
          </div>
        </Card>
      </div>

      <MerchantIDSettingsModal
        editData={editData}
        openModal={openModal}
        closeModal={closeModal}
        submitModal={onSubmitModal}
      />
    </div>
  );
};

export default MerchantIDSettings;
